import { number, object, string } from "yup";

export const homeownerSignupSchema = object({
  first_name: string().trim().required("First name is required."),
  last_name: string()
    .trim()
    .required("Last name is required.")
    .nullable()
    .notOneOf([null], "Last name is required."),
  email: string()
    .trim()
    .email("Invalid email format.")
    .required("Email is required."),
  phone: string()
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid phone format.")
    .required("Phone is required."),
  address_data: object().required("Address data is required.").nullable(),
  password: string().trim().required("Password is required."),
});

export const homeownerLoginSchema = object({
  email: string()
    .trim()
    .email("Invalid email format.")
    .required("Email is required."),
  password: string().required("Password is required."),
});

export const homeownerResetPasswordSchema = object({
  email: string()
    .trim()
    .matches(/^[^!@]+@[^!@]+\.[^!@]+$/, "Enter valid email.")
    .email("Invalid email address")
    .required("Email is required."),
  password: string().trim().required("Password is required."),
  password_confirmation: string()
    .trim()
    .required("Confirmation password is required."),
});

export const homeownerSetPasswordSchema = object({
  password: string().trim().required("Password is required."),
  password_confirmation: string()
    .trim()
    .required("Confirmation password is required."),
});
export const AddContactSchema = object()
  .shape({
    first_name: string()
      .trim()
      .matches(/^[a-zA-Z0-9]+$/, "Enter valid first name.")
      .required("First name is required."),
    last_name: string()
      .trim()
      .matches(/^[a-zA-Z0-9]+$/, "Enter valid last name."),
    email: string()
      .trim()
      .matches(/^[^!@]+@[^!@]+\.[^!@]+$/, "Enter valid email.")
      .email("Invalid email address"),
    phone: string().matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Enter valid phone #."),
  })
  .test(
    "email-or-phone",
    "Either email or phone number is required.",
    function (value) {
      const { email, phone } = value;
      if (!email && !phone) {
        return this.createError({
          path: "email",
          message: "Email is required.",
        });
      }
      return true;
    }
  );

export const homeownerForgotPasswordSchema = object({
  email: string().email("Invalid email format.").required("Email is required."),
});

export const homeownerPropertyRequestSchema = object({
  name: string().trim().required("Name is required."),
  email: string()
    .trim()
    .email("Invalid email format.")
    .required("Email is required."),
  phone: string()
    .required("Phone is required.")
    .matches(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/, "Invalid phone number."),
});

export const addFundSchema = object({
  amount: number()
    .required("Amount is required.")
    .min(1, "Amount must be at least 1.")
    .max(100, "Amount must be at most 100."),
});

export const idxSchema = object({
  idx_link: string()
    .trim()
    .required("URL is required.")
    .url("Invalid URL format."),
});
