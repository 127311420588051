export const SELLER = "seller";
export const INVESTOR = "investor";
export const BUYER = "investor";
export const HOMEHUB = "homehub";
export const OWNER = "Owner";
export const STARTUP = "Startup";

export const ADMIN = "admin";
export const AGENT = "agent";
export const HOMEOWNER = "homeowner";
export const CONTACT = "contact";

export const PRICE_FILTER = "PRICE_FILTER";
export const TYPE_FILTER = "TYPE_FILTER";
export const BEDS_FILTER = "BEDS_FILTER";
export const BATHS_FILTER = "BATHS_FILTER";
export const LEAD_FILTER = "LEAD_FILTER";

export const LAT_1 = 25.82;
export const LAT_2 = 49.38;
export const LONG_1 = -124.39;
export const LONG_2 = -66.94;
export const LAT_3 = 35.0;
export const LONG_3 = -90.0;

export const LAT_4 = 42.5;
export const LONG_4 = -100.5;

export const MOREPLUS_FILTER = "MOREPLUS_FILTER";

export const OFFER_TYPE_PLACE_YOUR_OFFER = "PLACE_YOUR_OFFER";
export const OFFER_TYPE_BUY_NOW = "BUY_NOW";
export const OFFER_TYPE_COUNTER_OFFER = "COUNTER_OFFER";

export const PROPERTY_STATUS_ACTIVE = "ACTIVE";
export const PROPERTY_STATUS_DRAFT = "DRAFT";
export const PROPERTY_STATUS_PENDING = "PENDING";
export const PROPERTY_STATUS_SOLD = "SOLD";
export const PROPERTY_STATUS_INACTIVE = "INACTIVE";

export const OFFER_STATUS_SUBMITTED = "SUBMITTED";
export const OFFER_STATUS_COUNTERED = "COUNTERED";
export const OFFER_STATUS_ACCEPTED = "ACCEPTED";
export const OFFER_STATUS_CANCELLED = "CANCELLED";
export const OFFER_STATUS_DECLINED = "DECLINED";
export const OFFER_STATUS_CLOSED = "CLOSED";

export const OFFER_STATUS_APPROVED = "APPROVED";
export const OFFER_STATUS_MODIFIED = "MODIFIED";
export const OFFER_STATUS_HOMEOWNER_ACCEPTED = "HOMEOWNER_ACCEPTED";
export const OFFER_STATUS_HOMEOWNER_COUNTERED = "HOMEOWNER_COUNTERED";
export const OFFER_STATUS_HOMEOWNER_DECLINED = "HOMEOWNER_DECLINED";

export const TYPE_IMAGE = "image";

export const MORNING = "Morning";
export const EVENING = "Evening";
export const AFTERNOON = "Afternoon";
export const NIGHT = "Night";

export const CONSENT_FORM_FILE_SIZE_LIMIT = 25000000;

export const CONSENT_FORM_STATUS_SENT = "SENT";
export const CONSENT_FORM_STATUS_APPROVE = "APPROVE";
export const CONSENT_FORM_STATUS_REJECT = "REJECT";

export const HO_PROPERTY_REQUEST_TYPE_OFFER = "OFFER";
export const HO_PROPERTY_REQUEST_TYPE_CASH = "CASH";
export const HO_PROPERTY_REQUEST_TYPE_MLS = "MLS";
export const HO_PROPERTY_REQUEST_TYPE_CMA = "CMA";
export const HO_PROPERTY_REQUEST_TYPE_PREAPPROVAL = "PREAPPROVAL";
export const HO_PROPERTY_REQUEST_TYPE_REFI = "REFI";

export const AS_BUYER = "as_buyer";
export const AS_SELLER = "as_seller";

export const AGENT_ROLE_MEMBER = "Member";
export const AGENT_ROLE_OWNER = "Owner";
export const AGENT_TYPE_SINGLE_AGENT = "SINGLE_AGENT";
export const AGENT_TYPE_TEAM = "TEAM";

export const SUBSCRIPTION_STATUS_CANCELLED = "canceled";
export const SUBSCRIPTION_STATUS_PAST_DUE = "past_due";

export const CONTACT_INFO_SOURCE_CONTACT = "CONTACT";
export const CONTACT_INFO_SOURCE_HOMEHUB = "HOMEHUB";

export const RELATIONSHIPS = "Relationships";
export const BUYING_CRITERIA = "Buying Criteria";
export const ALL = "all";

export const MIN_PURCHASE_PRICE = 0;
export const MAX_PURCHASE_PRICE = 1000000;
export const MIN_VALUE = 1;
export const MAX_VALUE = 100;
export const MIN_INTEREST = 0;
export const MAX_INTEREST = 50;
export const MIN_COST = 0;
export const MAX_COST = 20;

export const YEAR_1 = "1 year";
export const YEAR_2 = "2 years";
export const YEAR_3 = "3 years";
export const YEAR_5 = "5 years";
export const YEAR_10 = "10 years";
export const YEAR_15 = "15 years";
export const YEAR_20 = "20 years";
export const YEAR_25 = "25 years";
export const YEAR_30 = "30 years";
export const YEAR_35 = "35 years";
export const YEAR_40 = "40 years";
export const YEAR_45 = "45 years";
export const YEAR_50 = "50 years";
export const INPUT_TYPE_DETAIL = "detail";
export const INPUT_TYPE_RANGE = "range";

export const DATE_FORMATE = "MM/dd/yyyy";

export const CURRENCY = "currency";
export const RENTER = "Renter";
export const CASH_OFFER_LANDING_PAGE = "Cash Offer Landing Page";
export const HOME_VALUE_LANDING_PAGE = "Home Value Landing Page";
export const POSTCARD = "Postcard";

export const DROPDOWN = "dropdown";
export const DROPDOWN_WITH_VALUE = "dropdownWithValue";
export const CHECKBOX = "checkbox";
export const CHECKBOX_WITH_VALUE = "checkboxWithValue";
export const TEXTBOX = "textbox";
export const TEXTBOX_TYPE_NUMBER = "number";
export const TEXTBOX_TYPE_DATE = "date";
export const CHECKBOX_WITH_LABEL = "checkboxWithLabel";

export const OWNER_FORM = "owner_form";
export const VALUE_AND_EQUITY_FORM = "value_and_equity_form";
export const FINANCIALS_FORM = "financials_form";
export const FORECLOSURE_FORM = "foreclosure_form";
export const MLS_INFO_FORM = "mls_info_form";
