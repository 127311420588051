import React, { useRef, useState } from "react";

import ReactSelect from "react-select";
import clsx from "clsx";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useOnClickOutside } from "usehooks-ts";
import { useParams } from "react-router-dom";

import { estimatedHomeValueFilterOptions } from "../../api/data";
import {
  dropDownArrowStyle,
  getErrorForEstimatedValue,
  numberWithCommas,
} from "../../helpers/util";

import EstimatedHomeValueChart from "../../../home-owner/home/EstimatedHomeValueChart";
import { homeownerEstimatedValueChange } from "../../../shared/api/homehub";

import style from "../../../assets/css/homeowner/home.module.css";

const EstimatedEquity = ({
  propertyData,
  onClickRequestHomeValuation,
  setRequestType,
  homeownerRequestClick,
  isPublicRoute,
  propertyDataRefetch,
}) => {
  const [yearFilter, setYearFilter] = useState(
    estimatedHomeValueFilterOptions[0]
  );

  const queryClient = useQueryClient();
  const { id } = useParams();

  const [mortgageRateEdit, setMortgageRateEdit] = useState(false);
  const [mortgageRateUpdate, setMortgageRateUpdate] = useState(false);

  const [mortgageRate, setMortgageRate] = useState(
    propertyData?.last_estimated_value
  );
  const mortgageRateTextBoxRef = useRef(null);

  const handleYearFilterChange = (selected) => {
    setYearFilter(selected);
  };

  const { mutate } = useMutation(
    (data) => homeownerEstimatedValueChange(data, propertyData?.id),
    {
      onSuccess: (res) => {
        propertyDataRefetch && propertyDataRefetch();
        setMortgageRate(res?.data?.data?.last_estimated_value);
        queryClient.refetchQueries(["get-properties", id]);
        toast.success("Estimated Value updated successfully!");
      },
      onError: (err) => {
        toast.error(err.response.data.message);
      },
    }
  );

  const onOutsideClick = (e) => {
    if (mortgageRateUpdate) {
      const errorMessage = getErrorForEstimatedValue(mortgageRate);

      if (errorMessage) {
        toast.error(errorMessage);
        setMortgageRate(propertyData?.mortgage_rate);
      } else {
        mutate({ last_estimated_value: parseInt(mortgageRate) });
      }
    }

    setMortgageRateEdit(false);
    setMortgageRateUpdate(false);
  };
  const onChangeMortgageRate = (e) => {
    setMortgageRateUpdate(true);
    setMortgageRate(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setMortgageRateUpdate(true);
      onOutsideClick();
    }
  };

  useOnClickOutside(mortgageRateTextBoxRef, onOutsideClick);
  return (
    <div
      className={clsx("lg:col-span-2 py-3 maxMd:w-full", style.homeValueCard)}
    >
      <div className="flex gap-[10px] justify-between">
        <h3
          className={clsx(
            style.headingMd,
            "leading-[22.4px] text-base font-semibold"
          )}
        >
          Estimated home value
        </h3>
        <ReactSelect
          options={estimatedHomeValueFilterOptions}
          defaultValue={estimatedHomeValueFilterOptions[0]}
          value={yearFilter}
          className={"capitalize"}
          classNamePrefix={"react-select estimated-year_select"}
          styles={dropDownArrowStyle}
          onChange={handleYearFilterChange}
          isSearchable={false}
        />
      </div>
      <div className="flex gap-[10px] items-center">
        {isPublicRoute !== true && mortgageRateEdit ? (
          <div className={clsx("max-w-[120px]", style.monthlyPayment)}>
            <input
              ref={mortgageRateTextBoxRef}
              className="w-full px-2 py-1"
              defaultValue={propertyData?.last_estimated_value}
              onChange={(e) => onChangeMortgageRate(e)}
              onKeyDown={handleKeyPress}
              disabled={isPublicRoute === true}
            />
            <span>$</span>
          </div>
        ) : (
          <h1
            className="text-yankeesblue font-manrope font-extrabold lg:text-[40px] text-2xl leading-[52px] min-w-[130px]"
            onClick={() => setMortgageRateEdit(true)}
          >
            {numberWithCommas(propertyData?.last_estimated_value || "$0")}
          </h1>
        )}

        <span className="text-xs text-black md:max-w-[250px] tracking-[0.2px] leading-[16.8px]">
          Your home has gained an estimated{" "}
          {propertyData?.estimated_diff_data?.type !== "Positive" && "-"}
          {numberWithCommas(propertyData?.estimated_diff_data?.difference)}{" "}
          since you purchased it.
        </span>
      </div>
      <EstimatedHomeValueChart
        propertyData={propertyData}
        yearFilter={yearFilter}
        onClickRequestHomeValuation={() => {
          if (onClickRequestHomeValuation) {
            onClickRequestHomeValuation();
          }
          if (homeownerRequestClick) {
            setRequestType("CMA");
            homeownerRequestClick();
          }
        }}
      />
    </div>
  );
};

export default EstimatedEquity;
