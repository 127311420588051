import { omit } from "lodash";
import api from "..";

export const signup = (data) => api.post("investors/signup", data);

export const validateOnSignUp = (data) =>
  api.post("investors/investor-signup-fields", data);

export const login = (data) => api.post("investors/auth/login", data);

export const resetPassword = (data) =>
  api.post("investors/auth/reset/password", data);

export const forgotPassword = (data) =>
  api.post("investors/auth/password/email", data);

// export const logout = (data) => api.post("investors/auth/logout", data);

export const getProperties = (filters = {}) => {
  const excludedKeys = [
    "lat_3",
    "lat_4",
    "long_3",
    "long_4",
    "lead_types",
    "additionalFilter",
  ];

  // Create a new object without the excluded keys
  const filteredParams = omit(filters, excludedKeys);

  return api.get("properties/search", {
    params: { ...filteredParams, limit: "all" },
  });
};

export const getWholesalers = (page, search = "") => {
  return api.get("investors/wholesalers", {
    params: {
      page,
      search,
    },
  });
};

export const addWholesaler = (data) => api.post("investors/wholesalers", data);

export const getWholesaler = (buyer) =>
  api.get(`investors/wholesalers/${buyer}`);

export const updateWholesaler = (buyer, data) =>
  api.put(`investors/wholesalers/${buyer}`, data);

export const updateProfile = (data) => api.post("investors/profile", data);

export const makeAnOffer = (data) => api.post("offers", data);

export const getOffers = (page, search, orderByField, orderBy, type = null) =>
  api.get("offers", {
    params: {
      page,
      search,
      order_by_field: orderByField,
      order_by: orderBy,
      ...(type && { type: type }),
    },
  });

export const getCities = () => api.get("cities");

export const getSignupUser = (hash) => api.get(`/user?unique_hash=${hash}`);

export const saveFavourite = (property) => api.post(`/${property}/favourite`);

export const getFavProperites = (page) =>
  api.get("/favourite", {
    params: { page },
  });

export const getUserCriteria = () => api.get("investors/property-criterias");

export const updateCriteria = (criteria, data) =>
  api.post(`investors/property-criterias/${criteria}`, data);
