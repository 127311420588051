import {
  flatten,
  has,
  capitalize,
  reduceRight,
  values,
  mapValues,
  groupBy,
  maxBy,
  chain,
  take,
  last,
  filter,
} from "lodash";
import Cookies from "js-cookie";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import ReactDOM from "react-dom/client";
import moment from "moment";

import {
  ADMIN,
  AFTERNOON,
  AGENT,
  AGENT_ROLE_MEMBER,
  AGENT_TYPE_SINGLE_AGENT,
  BUYER,
  EVENING,
  HOMEHUB,
  HOMEOWNER,
  INVESTOR,
  MORNING,
  NIGHT,
  OFFER_STATUS_ACCEPTED,
  OFFER_STATUS_CANCELLED,
  OFFER_STATUS_CLOSED,
  OFFER_STATUS_COUNTERED,
  OFFER_STATUS_DECLINED,
  OFFER_STATUS_SUBMITTED,
  OWNER,
  SELLER,
} from "./constants";
import { createChat, getChat } from "../api/chat";
import {
  getAdminById,
  getAgentById,
  getHomeownerById,
  getInvestorById,
  getSellerById,
} from "../api";
import { formikData, SettingMenu } from "../api/data";

export const getServerErrors = (error) => {
  if (error?.response && error.response.data) {
    if (error.response.data.message) {
      return [error.response.data.message];
    }
    const response = error.response.data.errors || {};
    const arrays = Object.entries(response).map((value) => value[1]);
    return arrays?.length > 0 ? flatten(arrays) : ["Something went wrong"];
  }
  return [error?.message || "Something went wrong"];
};

export const hasError = (field, error) => {
  return has(error?.response?.data, field);
};

export const getToken = (tokenName) => {
  return Cookies.get(tokenName);
};

export const setToken = (tokenName, tokenValue) => {
  return Cookies.set(tokenName, tokenValue);
};

export const removeToken = (tokenName) => {
  return Cookies.remove(tokenName);
};

export function togglePassword() {
  var element = document.getElementsByClassName("password-js");
  if (element.type === "password") {
    element.type = "text";
  } else {
    element.type = "password";
  }
}

export const isUserSeller = (currentUserRole) => {
  return currentUserRole === SELLER;
};

export const isUserInvestor = (currentUserRole) => {
  return currentUserRole === INVESTOR;
};

export const isUserAdmin = (currentUserRole) => {
  return currentUserRole === ADMIN;
};

export const isUserAgent = (currentUserRole) => {
  return currentUserRole === AGENT;
};

export const isUserHomeowner = (currentUserRole) => {
  return currentUserRole === HOMEOWNER;
};

export const numberMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  decimalLimit: 2,
});

export const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  decimalLimit: 2,
});

export function unmaskNumbers(value) {
  if (!value) {
    return value;
  }
  return +`${value}`.replace(/[^0-9.]/g, "");
}

export const getLocation = (place) => {
  let _place = [];
  let area = [];

  for (var j = 0; j < place?.address_components?.length; j++) {
    _place.push(place?.address_components[j]);

    if (_place[j].types[0] === "street_number") {
      area["street_number"] = _place[j].long_name;
    }
    if (_place[j].types[0] === "route") {
      area["route"] = _place[j].short_name;
    }
    if (
      _place[j].types[0] === "locality" ||
      _place[j].types[0] === "neighborhood"
    ) {
      area["city"] = _place[j].long_name;
    }
    if (_place[j].types[0] === "postal_code") {
      area["zipCode"] = _place[j].long_name;
    }
    if (_place[j].types[0] === "administrative_area_level_1") {
      area["state_long"] = _place[j].long_name;
      area["state_short"] = _place[j].short_name;
    }
    if (_place[j].types[0] === "country") {
      area["short_country"] = _place[j].short_name;
    }
  }
  return area;
};

export const isPropertyActive = (splitLocation, name) => {
  return splitLocation[1] === name && "isActive";
};

export const shortCurrencyFormat = (value) => {
  if (isNaN(value)) {
    return value;
  }

  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const currencyFormat = (
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) => {
  if (isNaN(value)) {
    return value;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
};

export const jsxToElement = (jsx) => {
  const div = document.createElement("div");
  const root = ReactDOM.createRoot(div);
  root.render(jsx);
  return div;
};

export const getChatId = async (user1, user2, entity1, entity2) => {
  const chats = await getChat(user1, user2, entity1, entity2);
  if (chats.empty) {
    const res = await createChat(user1, user2, entity1, entity2);
    return res.id;
  }

  return chats.docs[0].id;
};

export const generateGreeting = () => {
  var currentHour = moment().format("HH");

  if (currentHour >= 12 && currentHour < 15) {
    return AFTERNOON;
  } else if (currentHour >= 15 && currentHour < 20) {
    return EVENING;
  } else if (currentHour >= 20 && currentHour < 3) {
    return NIGHT;
  } else {
    return MORNING;
  }
};

export const formatDate = (date) => {
  return moment(date).format("MM/DD/YYYY");
};
export const formatDateWithTime = (date) => {
  return moment(date).format("MM/DD/YYYY, HH:mm a");
};

export const formatDateForSorting = (date) => {
  return Number(moment(date).format("YYYYMMDD"));
};

export const getOppositeMessenger = (data, userInfo) => {
  if (!data || !userInfo) return null;

  const userId = userInfo?.id?.toString(); // Convert userInfo.id to a string for comparison

  if (userInfo.role === "agent") {
    // Get all numeric keys from data that are NOT equal to userInfo.id
    const numericKeys = Object.keys(data).filter(
      (key) => !isNaN(key) && key !== userId
    );

    if (numericKeys.length === 1) {
      // If only one numeric key is available after filtering, return it
      return numericKeys[0];
    } else if (numericKeys.length === 2) {
      // If two numeric keys are available, return the one that doesn't match userId
      return numericKeys[0] === userId ? numericKeys[1] : numericKeys[0];
    } else if (numericKeys.length === 0) {
      // If no numeric keys are left after filtering, check the defaultUserArr
      let defaultUserArr = [ADMIN, INVESTOR, SELLER, AGENT, HOMEOWNER];
      const foundRole = defaultUserArr.find(
        (role) => data.hasOwnProperty(role) && role !== "agent"
      );
      return foundRole || null;
    }
  } else {
    // Non-agent user case: Filter out the userId from numeric keys
    const numericKeys = Object.keys(data).filter(
      (key) => !isNaN(key) && key !== userId
    );

    if (numericKeys.length > 0) {
      // If there are numeric keys left, return the first one
      return numericKeys[0];
    }

    // Fallback to checking defaultUserArr for a matching role
    let defaultUserArr = [ADMIN, INVESTOR, SELLER, AGENT, HOMEOWNER];
    const oppositeMessenger = defaultUserArr.filter((user) => {
      return data.hasOwnProperty(user) && user !== userInfo?.role;
    });

    return oppositeMessenger.length > 0 ? oppositeMessenger[0] : null;
  }

  return null;
};

//not used
// export const getOppositeRole = (role) => {
//   let field = "";
//   if (role === "seller") {
//     field = "buyer";
//   } else if (role === "investor") {
//     field = "seller";
//   }
//   return field;
// };

export const getDueByOptions = (dueDate) => {
  let dueByDate = moment(dueDate, "YYYY-MM-DD HH:mm:ss").add(1, "days");
  let todayDate = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
  let totalMinutes = dueByDate.diff(todayDate, "minutes");

  let options = {
    days: Math.floor(totalMinutes / 1440),
    hours: Math.floor((totalMinutes % 1440) / 60),
    minutes: Math.floor(((totalMinutes % 1440) % 60) + 1),
  };
  return options;
};

export const isFileImage = (file) => {
  return file && file["type"].split("/")[0] === "image";
};

export const getUserfromUserType = (userType) => {
  if (userType) {
    let index = userType.lastIndexOf("\\");
    return userType.substring(index + 1).toLowerCase();
  }
};

export const getFormattedStatValue = (type, value) => {
  if (type === "static") {
    return value ? value : "0";
  } else if (type === "amount") {
    return value ? "$" + value?.toLocaleString("en-US") : "$0";
  } else if (type === "percent") {
    return value ? value + "%" : "0%";
  } else if (type === "range") {
    return value?.min_price && value?.max_price
      ? `$${value?.min_price.toLocaleString(
        "en-US"
      )} - $${value?.max_price.toLocaleString("en-US")}`
      : "$0 - $0";
  }
};

export const removeNullFromArrayOfJson = (data) => {
  let result = data.filter((element) => {
    if (element !== null) {
      return true;
    } else {
      return false;
    }
  });
  return result;
};

export const getCurrentEntityFromRole = (role) => {
  if (role === "seller") {
    return SELLER;
  } else if (role === "investor") {
    return INVESTOR;
  } else if (role === "admin") {
    return ADMIN;
  } else if (role === "agent") {
    return AGENT;
  } else if (role === "homeowner") {
    return HOMEOWNER;
  }
};

export const getRoleFromUserType = (userType) => {
  if (userType) {
    let index = userType.lastIndexOf("\\");
    let role = userType.substring(index + 1).toLowerCase();

    if (role === "seller") {
      return SELLER;
    } else if (role === "investor") {
      return INVESTOR;
    } else if (role === "buyer") {
      return INVESTOR;
    } else if (role === "admin") {
      return ADMIN;
    } else if (role === "agent") {
      return AGENT;
    } else if (role === "homeowner") {
      return HOMEHUB;
    }
  }
};

export const investorToBuyer = (role) => {
  if (role === INVESTOR) {
    return capitalize(BUYER);
  } else {
    return capitalize(role);
  }
};

export const getLoginUrl = (role) => {
  if (role === "seller") {
    return `${role}s/login`;
  } else if (role === "investor") {
    return `buyers/login`;
  } else if (role === "admin") {
    return `${role}/login`;
  } else if (role === "agent") {
    return `${role}s/login`;
  }
};

export const getOppMsgEntity = (pathname) => {
  let getUserByIdApi;
  let oppEntity;

  if (pathname.includes("buyers")) {
    getUserByIdApi = getInvestorById;
    oppEntity = INVESTOR;
  } else if (pathname.includes("sellers")) {
    getUserByIdApi = getSellerById;
    oppEntity = SELLER;
  } else if (pathname.includes("admins")) {
    getUserByIdApi = getAdminById;
    oppEntity = ADMIN;
  } else if (pathname.includes("agents")) {
    getUserByIdApi = getAgentById;
    oppEntity = AGENT;
  } else if (pathname.includes("homeowners")) {
    getUserByIdApi = getHomeownerById;
    oppEntity = HOMEOWNER;
  }

  return { oppEntity, getUserByIdApi };
};

export const getFileNameFromUrl = (url) => {
  return url?.substring(url.lastIndexOf("/") + 1);
};

export const getRegisteredUTCDatetoLocal = (registeredDate) => {
  const agentRegisteredDate = moment
    .utc(registeredDate, "YYYY-MM-DD HH:mm:ss")
    .local();
  return agentRegisteredDate.format("MM/DD/YYYY");
};

export const billingFormatDate = (dateStr) => {
  return moment(dateStr).format("DD MMMM YYYY");
};

export const getApiAndOppEntityFromRole = (sellerRole) => {
  let getUserByIdApi;
  let oppEntity;

  if (sellerRole === "seller") {
    getUserByIdApi = getSellerById;
    oppEntity = SELLER;
  } else if (sellerRole === "admin") {
    getUserByIdApi = getAdminById;
    oppEntity = ADMIN;
  } else if (sellerRole === "agent") {
    getUserByIdApi = getAgentById;
    oppEntity = AGENT;
  } else if (sellerRole === "investor") {
    getUserByIdApi = getInvestorById;
    oppEntity = INVESTOR;
  } else if (sellerRole === "homeowner") {
    getUserByIdApi = getHomeownerById;
    oppEntity = HOMEOWNER;
  }

  return { oppEntity, getUserByIdApi };
};

/*
That we are using for dropdown up-down.
*/
export const dropDownArrowStyle = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
};

export const requestModalBtnText = {
  OFFER: "Get Offers Now",
  CASH: "Cash out my equity",
  MLS: "List my home on the MLS",
  CMA: "List my home on the CMA",
};

export const requestModalSuccessMessage = {
  OFFER: "We will reach out to you shortly with offer options for your home.",
  CASH: "We will reach out to you shortly with to get started with your home equity line of credit.",
  MLS: "We will reach out to you shortly to discuss your home selling goals.",
  CMA: "We will reach out to you shortly with CMA options for your home.",
};

export const formatDateToNow = (date) => {
  let providedDate = moment(date);
  let distance = providedDate.fromNow();
  return distance;
};

export const generateEstimatedHomeValueChartData = (
  propertyData,
  yearFilter
) => {
  const soldProperties =
    filter(propertyData?.chart_data, (item) => item.amount !== 0) || [];

  const currentDate = new Date();

  //generate labels
  let labels = [];
  if (yearFilter.value === 1) {
    for (let i = 0; i < 12; i++) {
      labels.push(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - i
        ).toLocaleDateString("en", { month: "short", year: "numeric" })
      );
    }
  } else {
    if (yearFilter.value === "all") {
      const result = values(
        mapValues(
          groupBy(soldProperties, (item) => new Date(item.date).getFullYear()),
          (items) => maxBy(items, "date")
        )
      );

      result.forEach((sale) => {
        const year = moment(sale.date).format("YYYY");
        if (!labels.includes(year)) {
          let index = 0;
          while (
            index < labels.length &&
            parseInt(labels[index]) > parseInt(year)
          ) {
            index++;
          }
          labels.splice(index, 0, year);
        }
      });
    } else {
      const currentYear = currentDate.getFullYear();
      for (let i = 0; i < yearFilter.value; i++) {
        labels.push((currentYear - i).toString());
      }
    }
  }

  let chartDataValue = new Array(labels.length).fill(0);
  const result = values(
    mapValues(
      groupBy(soldProperties, (item) => new Date(item.date).getFullYear()),
      (items) => maxBy(items, "date")
    )
  );

  function findPreviousValue(yearOrDate) {
    // Parse the input year or date
    let targetDate;
    if (isNaN(Date.parse(yearOrDate))) {
      // If input is just a year, parse it as a year only
      targetDate = new Date(`${yearOrDate}-01-01`);
    } else {
      targetDate = new Date(yearOrDate);
    }

    // Filter out only sold entries and sort them by date in descending order
    const soldData = soldProperties
      .filter((item) => item.is_sold)
      .map((item) => ({
        ...item,
        date: new Date(item.date),
      }))
      .sort((a, b) => b.date - a.date); // Sort in descending order

    // Find the nearest value before the targetDate
    for (const item of soldData) {
      if (item.date < targetDate) {
        return item.amount;
      }
    }

    // Check if targetDate is before the earliest date in soldData
    if (
      soldData.length > 0 &&
      targetDate < soldData[soldData.length - 1].date
    ) {
      return 0;
    }

    // If no matching data found, return null
    return null;
  }

  if (yearFilter.value === 1) {
    // Use soldProperties
    const filteredData = chain(soldProperties)
      .groupBy((item) => take(item.date.split("-"), 2).join("-")) // Group by year and month
      .map((group) => last(group)) // Keep only the last item in each group
      .value();

    filteredData?.forEach((property) => {
      let checkLabelValueWith = moment(property.date).format("MMM YYYY");
      const labelIndex = labels.indexOf(checkLabelValueWith);
      if (labelIndex !== -1) {
        chartDataValue[labelIndex] += property.amount;
      }
    });
  } else {
    // Use result
    result.forEach((item) => {
      const checkLabelValueWith = new Date(item.date).getFullYear().toString();
      const labelIndex = labels.indexOf(checkLabelValueWith);

      if (labelIndex !== -1) {
        chartDataValue[labelIndex] += parseInt(item.amount); // Adjust based on your data structure
      }
    });
  }

  const chartValue = reduceRight(
    chartDataValue,
    (acc, value, index, array) => {
      if (value !== 0) {
        acc.unshift(value);
      } else {
        if (index === array.length - 1) {
          acc.unshift(findPreviousValue(labels[labels.length - 1]));
        } else {
          acc.unshift(acc[0]); // Ensuring the same value is carried forward
        }
      }
      return acc;
    },
    []
  );

  const filterData = (dataSource) => {
    return labels.map((label) => {
      let found = false;
      dataSource.forEach((data) => {
        const [year, month] = data.date.split("-").slice(0, 2); // Extract year and month
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthIndex = parseInt(month, 10) - 1;
        const formattedDate = `${monthNames[monthIndex]} ${year}`; // Format month and year

        if (label.includes(" ")) {
          // Label contains both month and year
          if (formattedDate === label && data.is_sold) {
            found = true;
          }
        } else {
          // Label contains only year
          if (year === label && data.is_sold) {
            found = true;
          }
        }
      });
      return found && "Sold";
    });
  };
  const today = moment();
  const oneYearAgo = moment().subtract(1, "year");

  const filteredProperties = filter(soldProperties, (property) => {
    const propertyDate = moment(property.date);
    return (
      property.is_sold && propertyDate.isBetween(oneYearAgo, today, null, "[]")
    );
  });

  const dataSource = yearFilter.value === 1 ? filteredProperties : result;
  const soldData = filterData(dataSource);
  const soldValue = soldData.reverse();
  return { labels, chartValue, soldValue };
};

export const numberWithCommas = (number) => {
  if (number === undefined || number === null || isNaN(number)) {
    return "0";
  }

  const formattedNumber = number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  if (formattedNumber === undefined || formattedNumber === null) {
    return "0";
  }

  return formattedNumber.replace(/\.00$/, "");
};

export const getOfferGreetingMessage = (offerData, user) => {
  if (offerData?.status === OFFER_STATUS_SUBMITTED) {
    return offerData?.submitted_by_type === user?.user_type
      ? "you've submitted a new offer!"
      : getRoleFromUserType(offerData?.property?.user_type) === user?.role
        ? "you've received a new offer!"
        : `offer status is ${offerData.status.toLowerCase()}`;
  }
  if (offerData?.status === OFFER_STATUS_COUNTERED) {
    return offerData?.submitted_by_type === user?.user_type
      ? "you've submitted a counter offer!"
      : getRoleFromUserType(offerData?.opposite_user?.user_type) ===
        user?.role && offerData?.opposite_user?.user_id === user?.id
        ? "you've received a counter offer!"
        : `offer status is ${offerData.status.toLowerCase()}`;
  }
  if (
    offerData?.status === OFFER_STATUS_ACCEPTED ||
    offerData?.status === OFFER_STATUS_CANCELLED ||
    offerData?.status === OFFER_STATUS_DECLINED ||
    offerData?.status === OFFER_STATUS_CLOSED
  ) {
    return offerData?.submitted_by_type === user?.user_type
      ? `you've ${offerData?.status.toLowerCase()} the offer!`
      : getRoleFromUserType(offerData?.opposite_user?.user_type) ===
        user?.role && offerData?.opposite_user?.user_id === user?.id
        ? `your offer is ${offerData?.status.toLowerCase()}!`
        : `offer status is ${offerData.status.toLowerCase()}`;
  }
};

export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function TwoDecimalsForPercentage(value) {
  if (value !== undefined && value !== null) {
    return `${value?.toFixed(2)}`;
  } else {
    return "N/A";
  }
}
// that we are using for call
export const onClickCall = (number) => {
  const telUri = `tel:${number}`;
  window.location.href = telUri;
};
const createTooltipElement = () => {
  const tooltipEl = document.createElement("div");
  tooltipEl.style.cssText = `
    background: #192841;
    border-radius: 6px;
    color: white;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, 0);
    transition: all .1s ease;
    z-index: 2;
  `;

  const table = document.createElement("table");
  table.style.margin = "0";
  tooltipEl.appendChild(table);

  return tooltipEl;
};

const updateTooltipContent = (tooltipEl, tooltip, soldvalue) => {
  if (!tooltip.body) return;

  const { dataset, dataIndex } = tooltip.dataPoints[0] || {};

  if (!dataset) return;

  const value = numberWithCommas(dataset.data[dataIndex]);
  const label = soldvalue[dataIndex] || "";

  const soldText =
    label !== ""
      ? `<span style="width: 28px; height: 15px; background: white; border-radius: 3px; color: #192841; padding: 3px; margin-right: 5px;">${label}</span>`
      : "";

  const content = `${soldText}<span>${value}</span>`;

  const tableRoot = tooltipEl.querySelector("table");
  tableRoot.innerHTML = `<tbody><tr style="background-color: inherit; border-width: 0;"><td style="border-width: 0;">${content}</td></tr></tbody>`;
};

export const externalTooltipHandler = (context, soldvalue) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  tooltipEl.style.opacity = tooltip.opacity === 0 ? 0 : 1;

  updateTooltipContent(tooltipEl, tooltip, soldvalue);

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + "px";
};

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = createTooltipElement();
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const removeSpaces = (value) => {
  return value?.toLowerCase()?.trim()?.replace(/\s/g, "");
};

export function bytesToKilobytes(bytes) {
  return `${(bytes / 1024).toFixed(0)}KB`;
}

export function getSettingMenu(user) {
  let routesToExclude = [];
  if (user.platform_owner) {
    if (user.agent_role === AGENT_ROLE_MEMBER) {
      routesToExclude = [
        "manage-billing",
        "manage-agents",
        "team-settings",
        "manage-brokers",
        "manage-tags",
        "archive-contacts",
      ];
    } else {
      routesToExclude = ["manage-billing"];
    }
  } else if (user.agent_role === AGENT_ROLE_MEMBER) {
    routesToExclude = [
      "manage-billing",
      "manage-agents",
      "team-settings",
      "manage-brokers",
      "manage-tags",
      "archive-contacts",
    ];
  } else if (user.agent_type === AGENT_TYPE_SINGLE_AGENT) {
    routesToExclude = ["manage-agents", "team-settings"];
  }
  return routesToExclude.length > 0
    ? SettingMenu.filter((item) => !routesToExclude.includes(item.navigate_url))
    : SettingMenu;
}

export const checkHostNameAndRedirect = () => {
  if (process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT === "local") {
    return false;
  }

  const targetHostname = process.env.REACT_APP_HOST_NAME;
  const url = new URL(window.location.href);
  const hostname = url.hostname;

  if (hostname !== targetHostname) {
    return true;
  }

  return false;
};

export const itIsOwner = (role) => role === OWNER;

export const checkLimit = (SubscriptionLimit, curentData, platformOwner) =>
  platformOwner ? false : SubscriptionLimit <= curentData;

export const hasSubDomain = () => {
  const mainDomain = process.env.REACT_APP_HOST_NAME;

  const parsedUrl = new URL(window.location.href);

  const hostname = parsedUrl.hostname;

  const hostnameParts = hostname.split(".");
  const mainDomainParts = mainDomain.split(".");

  const mainDomainMatch =
    hostnameParts.slice(-mainDomainParts.length).join(".") === mainDomain;

  const hasSubdomain = hostnameParts.length > mainDomainParts.length;

  return mainDomainMatch && hasSubdomain;
};

export const getErrorForMortgageRate = (rate) => {
  if (rate < 0) {
    return "Please enter a number greater than or equal to 1.01";
  } else if (rate > 100) {
    return "Please enter a number less than or equal to 100";
  } else if (isNaN(rate)) {
    return "Please enter a number";
  }

  return null;
};

export const getErrorForEstimatedValue = (rate) => {
  if (rate < 1) {
    return "Please enter a number greater than 1";
  }
  if (isNaN(rate)) {
    return "Please enter a number";
  }
  return null;
};
export function isUpsideAppTenant(url) {
  // eslint-disable-next-line no-useless-escape
  const match = url.match(/^https?:\/\/([^\/.]+)/);
  const urlTenant = match ? match[1] : "";
  return urlTenant === process.env.REACT_APP_DOMAIN_BASE_NAME;
}

export const getRequestTypeDisplay = (requestType) => {
  switch (requestType) {
    case "CASH":
      return "HELOC";
    case "OFFER":
      return "OFFER";
    case "MLS":
      return "MLS";
    case "CMA":
      return "CMA";
    case "REFI":
      return "REFI";
    case "PREAPPROVAL":
      return "PRE-APPROVAL";
    default:
      return "N/A";
  }
};

export function getStreetAddress(fullAddress) {
  return fullAddress?.split(",")[0].trim();
}

export const unmaskPhone = (phone) => {
  return phone.replace(/\D/g, "");
};

// that function we're using for current date

export const getCurrentDate = () => {
  const today = new Date();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };
  return today.toLocaleDateString("en-US", options);
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const showMoreFormatAddress = (addressData) => {
  if (addressData === null) {
    return <p>-</p>;
  }
  const { address, city } = addressData;
  // Check if 'address' and 'city' are valid strings and if the city is part of the address
  if (address && city && address.includes(city)) {
    const splitAddress = address.split(city);
    const firstLine = splitAddress[0].trim();
    const secondLine = city + splitAddress[1].trim();

    return (
      <>
        <p className="mb-1">{firstLine}</p>
        <p>{secondLine}</p>
      </>
    );
  }

  // Return the full address if no city match or city is missing
  return <p>{address || "No address available"}</p>;
};

export const formatAddress = (addressData) => {
  if (!addressData) {
    return <p>-</p>;
  }

  const { house, street, city, state, zip } = addressData;

  if (house && street && city && state && zip) {
    return (
      <>
        <p className="mb-1">{`${house} ${street}`.trim()}</p>
        <p>{`${city}, ${state} ${zip}`}</p>
      </>
    );
  }

  return <p>No address available</p>;
};

export const formatAddressForRequests = (addressData) => {
  if (!addressData) {
    return <p>-</p>;
  }

  const { house, street, city, state, zip } = addressData;

  if (house && street && city && state && zip) {
    return (
      <>
        <p className="mb-1 font-inter-bold">{`${house} ${street}`.trim()}</p>
        <p className="font-inter-medium">{`${city}, ${state} ${zip}`}</p>
      </>
    );
  }

  return <p>No address available</p>;
};

export function getInitials(name) {
  const nameParts = name.trim().split(" ");
  if (nameParts.length >= 2) {
    const firstInitial = nameParts[0][0].toUpperCase();
    const lastInitial = nameParts[1][0].toUpperCase();
    return firstInitial + lastInitial;
  }
  return ""; // Return an empty string if the name format isn't as expected
}

// Function to combine first and last name with capitalization
export const getUserName = (firstName, lastName) => {
  return `${capitalize(firstName)} ${capitalize(lastName)}`;
};

export const getOrdinalRelationship = (indexValue) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = indexValue % 100;
  const suffix = suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0];
  return `${indexValue}${suffix} Relationship`;
};

export const logCallCheckboxOptions = [
  { name: "spoke_to_lead", label: "Spoke to lead" },
  { name: "no_answer", label: "No Answer" },
  { name: "scheduled_buyer_appointment", label: "Scheduled Buyer Appointment" },
  { name: "wrong_number", label: "Wrong Number" },
  {
    name: "scheduled_listing_appointment",
    label: "Scheduled Listing Appointment",
  },
  { name: "opted_out_of_calls", label: "Opted out of calls" },
  { name: "left_message", label: "Left Message" },
];

export const formatToLocalDateTime = (dueDate, time) => {
  // Combine the date and time into a single string
  const combinedDateTimeString = `${dueDate} ${time}`; // Example: "2024-11-27 05:00:00"

  // Parse the combined string as UTC
  const dueDateTimeUtc = moment.utc(
    combinedDateTimeString,
    "YYYY-MM-DD HH:mm:ss"
  );

  // Convert it to local time and format it
  const dueDateTimeLocal = dueDateTimeUtc
    .local()
    .format("MMMM Do, YYYY h:mm A");

  // Return the formatted result in local timezone
  return dueDateTimeLocal;
};

export function getClosestDate(date1, date2) {
  const now = moment(); // Get current time

  // Convert both dates to moment objects
  const momentDate1 = date1 ? moment(date1) : null;
  const momentDate2 = date2 ? moment(date2, "YYYY-MM-DD HH:mm:ss") : null;

  // If either date is null, return the other one as the closest
  if (!momentDate1) return momentDate2 ? momentDate2.fromNow() : null;
  if (!momentDate2) return momentDate1.fromNow();

  // Calculate the difference between now and both dates
  const diff1 = Math.abs(now.diff(momentDate1));
  const diff2 = Math.abs(now.diff(momentDate2));

  // Return the date that is closer to the current time
  if (diff1 < diff2) {
    return momentDate1.fromNow(); // Closer date1
  } else {
    return momentDate2.fromNow(); // Closer date2
  }
}

export const calculateDerivedValues = (analyzerValue) => {
  const {
    purchasePrice,
    downPayment,
    interestOnLoan,
    monthlyRent,
    vacancy,
    closingCosts,
    managementFee,
    maintenance,
    leasingFees,
    insurance,
    capitalExpenses,
    hoaFees,
    propertyTax,
    utilities,
    cleaningFees,
    monthlyOperatingExpensesOther,
    loanTerm,
    homeFurnishings,
    incomeOther,
    renovations,
  } = analyzerValue;

  // 1. Annual Rent (already provided)

  const annualRent = monthlyRent * 12;

  // 2. Monthly Rent (already provided)
  const monthlyRentValue = monthlyRent;

  // 24. management Fee value
  const ManagementFeeValue = (monthlyRentValue * managementFee) / 100;
  // 25. Leasing Fee value
  const LeasingFeeValue = (monthlyRentValue * leasingFees) / 100;
  // 26. Capital Fee value
  const CapitalExpenseFeeValue = (monthlyRentValue * capitalExpenses) / 100;
  // 27. Property Tax value
  const PropertyTaxValue = (monthlyRentValue * propertyTax) / 100;
  // 27. Property Tax value
  const MaintenanceValue = maintenance;

  // 3. Monthly Operating Expense
  const monthlyOperatingExpenses =
    ManagementFeeValue +
    MaintenanceValue +
    LeasingFeeValue +
    insurance +
    CapitalExpenseFeeValue +
    hoaFees +
    PropertyTaxValue +
    utilities +
    cleaningFees +
    monthlyOperatingExpensesOther;

  // 23. Vacancy value
  const vacancyValue = (monthlyRentValue * vacancy) / 100;
  const vacancyAdjustMentValue = annualRent - (annualRent * vacancy) / 100;
  // 5. Net Operating Income (NOI)
  const annualOperatingExpenses = monthlyOperatingExpenses * 12;
  const netOperatingIncome = vacancyAdjustMentValue - annualOperatingExpenses;
  const grossIncome = vacancyAdjustMentValue;

  // 6. NOI (same as above)
  const noi = netOperatingIncome;

  // 7. Cap Rate
  const capRate = (noi / purchasePrice) * 100;

  // 4. Gross Yield

  const grossYield = (vacancyAdjustMentValue / purchasePrice) * 100;

  // 8. Gross Income
  const grossIncomeValue = grossIncome;

  // 9. Monthly Rent (already provided)
  const monthlyRentFinal = monthlyRent;

  // 10. Annual Gross Income
  const annualGrossIncome = grossIncome;

  // 11. Annual Rent (already calculated)
  const annualRentFinal = annualRent;

  const loanAmount = purchasePrice - (purchasePrice * downPayment) / 100;

  const monthlyInterestRate = interestOnLoan / 12 / 100;

  // 13. Monthly Debt Service (Loan Payment Calculation)

  const loanTermInMonths = loanTerm * 12;
  const monthlyLoanPayment =
    (loanAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, loanTermInMonths)) /
    (Math.pow(1 + monthlyInterestRate, loanTermInMonths) - 1);

  // 14. Monthly Loan Payment (already calculated above)
  const monthlyLoanPaymentFinal = monthlyLoanPayment;

  // 15. Annual Loan Payment
  const annualLoanPayment = monthlyLoanPayment * 12;

  // 16. Annual Debt Service
  const annualDebtService = annualLoanPayment;

  // 17. Annual Cash Flow
  const annualCashFlow = netOperatingIncome - annualDebtService;

  // 18. Total Cash Invested

  const totalCashInvested =
    (purchasePrice * downPayment) / 100 +
    (purchasePrice * closingCosts) / 100 +
    homeFurnishings +
    incomeOther +
    renovations;

  // 19. Cash on Cash Return
  const cashOnCashReturn = (annualCashFlow / totalCashInvested) * 100;

  // 20. Monthly Cash Flow
  const monthlyCashFlow = annualCashFlow / 12;

  // 21. Net Income
  const netIncome =
    monthlyRentValue -
    (monthlyRentValue * vacancy) / 100 -
    monthlyOperatingExpenses;

  // 22. Monthly Cash Flow (same as above)
  const monthlyCashFlowFinal = netIncome - monthlyLoanPayment;

  return {
    annualRent,
    annualGrossIncome,
    monthlyOperatingExpenses,
    annualOperatingExpenses,
    grossIncome,
    netOperatingIncome,
    noi,
    capRate,
    monthlyCashFlow,
    annualCashFlow,
    grossYield,
    vacancyValue,
    grossIncomeValue,
    monthlyLoanPaymentFinal,
    cashOnCashReturn,
    monthlyCashFlowFinal,
    monthlyRentFinal,
    annualRentFinal,
  };
};

export const formatDateToMMDDYYYY = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const downloadPdfFile = (url, fileName = "download.pdf") => {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generatePayloadKeys = (formConfig) => {
  const extractPayloadKeys = (config, result = {}) => {
    if (Array.isArray(config)) {
      config.forEach((item) => {
        if (item.payloadKey) {
          const payloadKeys = Array.isArray(item.payloadKey)
            ? item.payloadKey
            : [item.payloadKey];
          payloadKeys.forEach((key) => {
            result[key] = null;
          });
        }
        extractPayloadKeys(item, result);
      });
    } else if (typeof config === "object") {
      Object.keys(config).forEach((key) => {
        const field = config[key];
        if (field.payloadKey) {
          const payloadKeys = Array.isArray(field.payloadKey)
            ? field.payloadKey
            : [field.payloadKey];
          payloadKeys.forEach((payload) => {
            result[payload] = null;
          });
        }
        extractPayloadKeys(field, result);
      });
    }
    return result;
  };

  return extractPayloadKeys(formikData?.[formConfig]);
};
export const formatNumber = (value) => {
  if (value == null || isNaN(value)) {
    return 0;
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
