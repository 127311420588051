import { find, get, merge, omit } from "lodash";
import api from "..";
import { unmaskNumbers } from "../../helpers/util";
import { type_of_property } from "../data";

export const login = (data) => api.post("agents/auth/login", data);

export const withoutTenantLogin = (data) => api.post("agents/login", data);

export const forgotPassword = (data) =>
  api.post("agents/auth/password/email", data);

export const withoutTenantForgotPassword = (data) =>
  api.post("agents/password/email", data);

export const resetPassword = (data) =>
  api.post("agents/auth/reset/password", data);

// export const logout = (data) => api.post("agents/auth/logout", data);

export const getBuyers = (page, search = "") => {
  return api.get("investors", {
    params: {
      page,
      search,
    },
  });
};

export const addBuyer = (data) => api.post("investors", data);

export const getBuyer = (investor) => api.get(`investors/${investor}`);

export const updateBuyer = (investor, data) =>
  api.put(`investors/${investor}`, data);

export const deleteBuyer = (id) => api.delete(`investors/${id}`);

// property crud
export const addProperty = (data) => api.post("properties", data);

export const updateProperty = (property, data) =>
  api.post(`properties/${property}`, data);

export const deleteProperty = (id) => api.delete(`properties/${id}`);

export const updateProfile = (data) => api.post("/profile", data);
export const updateCustomizeColumns = (data) => api.patch("/profile", data);

export const updateProfileLocation = (data) =>
  api.post("agents/locations", data);

// leads
export const getLeads = (page, search) =>
  api.get("/agents/leads", {
    params: {
      page,
      search,
    },
  });
// to get theHomeOwners on agentLeads
// export const getHomeowners = (page , search) => api.get("/home-owner-users", {
//   params:{
//     page,
//     search,
//   },
// });
export const getHomeowners = async (search) => {
  const res = await api.get("/home-owner-users", {
    params: {
      limit: "all",
      search: search ?? "",
    },
  });
  return res;
};

export const postAddHomeOwner = (lead, data) => {
  return api.post(`/agents/lead/${lead}`, data);
};

export const postAddLeadNote = (lead, data) => {
  return api.put(`/agents/lead/${lead}`, data);
};

export const deleteLead = (lead) => {
  return api.delete(`agents/lead/${lead}`);
};

// that we're using for new agent api
export const agentSignupApi = (data) => api.post("agent/signup", data);

export const agentSignupSuggestDomainNameApi = (data) =>
  api.post("suggest-domain-name", data);

export const agentSignupCheckDomainApi = (data) =>
  api.post("check-domain", data);

export const agentSignupSentOtpApi = (data) => api.post("send-otp", data);

export const agentSignupVerifyOtpApi = (data) => api.post("verify-otp", data);

export const retrieveDomain = (data) => api.post("retrive-domain", data);

export const currentSubscription = (data) =>
  api.post("current-subscription", data);

export const getTenant = () => api.get("tenant");

export const getContacts = (page, search = "", limit) =>
  api.get("/agents/contacts", {
    params: {
      page,
      search,
      limit,
    },
  });

export const filterContacts = (page, search = "", limit, agentId, data) =>
  api.post("/agents/get/contacts", {
    page: page,
    search: search,
    limit: limit,
    filters: data,
    agent_id: agentId,
  });
export const getContactsAll = () => api.get("/agents/select/contacts");
export const getContactById = (contactId) =>
  api.get(`/agents/contacts/${contactId}`);
export const getContactIds = () =>
  api.get(`/agents/contacts-count
`);

export const getContactAddress = (search, type) =>
  api.post("/address-auto-complete", {
    address: search,
    type: type,
  });

export const deleteContacts = (data) => {
  return api.delete("/agents/contacts", {
    data: { ids: data },
  });
};

export const importContacts = (data) => {
  return api.post("/agents/contacts-import", data);
};
export const exportContacts = (data) => {
  return api.post("/agents/contacts-export", {
    ids: data,
  });
};
export const sendHomeUpdateToContacts = (data) => {
  return api.post("/agents/home-value", {
    ids: data,
  });
};

export const inviteContacts = (data) => {
  return api.post("/agents/invite-contacts", {
    ids: data,
  });
};

export const cancelSubscription = () => api.post("/cancel-subscription");
export const resumeSubscription = () => api.post("/resume-subscription");

export const upgradeSubscription = (id) =>
  api.post("/update-subscription", { price_id: id });
export const addContact = (data) => api.post("/agents/contacts", data);
export const reAssignAgent = (data) =>
  api.put(`/agents/contacts`, {
    agent_id: data?.agentId,
    ids: data?.contactId,
  });

export const reAssignSingleAgent = (data) => {
  api.put(`/agents/contacts`, {
    agent_id: data?.agentId?.value?.id,
    ids: data?.contactId,
  });
};
export const customPortal = () => api.get("/customer-portal");

export const invoicePortal = () => api.get("/invoice-portal");

export const paymentMethodsPortal = () => api.get("/payment-portal");

export const getPlans = () => api.get("plans");

export const getAgentsDetails = (page, search = "", limit) =>
  api.get("agents", {
    params: { page, search, limit },
  });

export const getAgentsForContact = () =>
  api.get("agents", {
    params: {
      limit: "all",
    },
  });
export const getSingleAgentsDetails = (agentId) => api.get(`agents/${agentId}`);

export const addAgents = (data) => api.post("agents", data);

export const updateAgents = (agentId, data) =>
  api.put(`agents/${agentId}`, data);

export const deleteAgent = (agentId) => api.delete(`agents/${agentId}`);

export const getAgentsStats = (
  selectedAgentId,
  selectedAgentType,
  timeFilter,
  dateFilter = {}
) =>
  api.get("/agents/stats", {
    params: {
      agent_id: selectedAgentType === "single" ? selectedAgentId : null,
      stats_type: selectedAgentType,
      type: timeFilter,
      ...dateFilter,
    },
  });

export const updateTeamDetails = (data) => api.post("team", data);

export const getBrokerageDetails = () => api.get("brokerage");

export const updateBrokerageDetails = (data) => api.post("brokerage", data);

export const revealAddress = (data) =>
  api.post("reveal-address", { ids: data });

export const addRelationship = (data) =>
  api.post(`/agents/contacts/${data.contact_id}/relationships`, data);

export const editRelationship = (data) =>
  api.put(
    `/agents/contacts/${data?.contact_id}/relationships/${data?.id}`,
    omit(data, "id")
  );

export const deleteRelationship = ({ id, contact_id }) =>
  api.delete(`/agents/contacts/${contact_id}/relationships/${id}`);

export const addTag = ({ contact_id, name }) =>
  api.post(`/agents/contacts/${contact_id}/tags`, { name: name });
export const getTag = () => api.get("/agents/tags");
export const deleteTage = (data) => {
  return api.delete(`agents/contacts/${data.contact_id}/tags/${data.tag_id}`);
};

export const addBuyingCriteria = (data) =>
  api.put(`agents/contacts/${data?.contact_id}}/property-criteria`, {
    ...omit(data, "contact_id"),
    addresses: [data.address_data],
  });

export const addNote = (data) =>
  api.post(`/agents/contacts/${data?.contact_id}/notes`, data);
export const addCall = (data) =>
  api.post(`/agents/contacts/${data?.contact_id}/calls`, data);
export const addTask = (data) =>
  api.post(
    `/agents/contacts/${data?.contact_id}/tasks`,
    omit(data, "contact_id")
  );
export const updateTask = (data) =>
  api.put(
    `/agents/contacts/${data?.contact_id}/tasks/${data?.id}`,
    omit(data, "contact_id")
  );

export const getPropertyCriteria = (contact_id) =>
  api.get(`/agents/contacts/${contact_id}/property-criteria`);

export const getrelationship = (contact_id) =>
  api.get(`/agents/contacts/${contact_id}/relationships`);

export const getTags = (contact_id) =>
  api.get(`/agents/contacts/${contact_id}/tags`);

export const getContactTask = (contact_id) =>
  api.get(`/agents/contacts/${contact_id}/tasks`);

export const getCommunicationHistory = (contact_id, type) =>
  api.get(`agents/contacts/${contact_id}/communication-history?type=${type}`);

// To move contact to archive
export const archiveDeleteContact = (contactId) =>
  api.put(`agents/contacts/archive`, {
    contact_ids: [contactId],
  });

export const archiveDeleteMultipleContact = (contactId) =>
  api.put(`agents/contacts/archive`, {
    contact_ids: contactId,
  });

// To get all archive contacts

export const getArchiveContact = (page, search = "", limit, agentId) =>
  api.post("agents/contacts/archive", {
    page: page,
    search: search,
    limit: limit,
    agent_id: agentId,
  });

// To Unarchive contact
export const unArchiveContact = (contactIds) =>
  api.put(`agents/contacts/unarchive`, {
    contact_ids: contactIds,
  });

export const communicationHistory = (contact_id, id) =>
  api.put(`agents/contacts/${contact_id}/communication-history/${id}`);

export const taskComplete = (data) =>
  api.put(
    `/agents/contacts/${data?.contact_id}/tasks/mark/read`,
    omit(data, "contact_id")
  );

export const deleteTask = ({ id, contact_id }) =>
  api.delete(`/agents/contacts/${contact_id}/tasks/${id}`);

// To get all tag contacts
export const getAgentsTags = (page, search = "", limit, agentId) =>
  api.post("agents/tags", {
    page: page,
    search: search,
    limit: limit,
    agent_id: agentId,
  });

export const deleteAgentsTags = (id) => {
  return api.delete(`agents/tags/${id}`);
};

export const addContactsTag = (data) => api.post("/agents/tags", data);

export const updateContactsTag = (id, data) =>
  api.put(`agents/tags/${id}`, data);

export const getTaskInfoById = ({ id, contact_id }) =>
  api.get(`/agents/contacts/${contact_id}/tasks/${id}`);

export const addDocument = ({ data, contact_id }) =>
  api.post(`/agents/contacts/${contact_id}/documents`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateDocumentFileName = (contact_id, id, newName) =>
  api.patch(`/agents/contacts/${contact_id}/documents/${id}`, {
    name: newName,
  });

export const deleteDocument = ({ id, contact_id }) =>
  api.delete(`/agents/contacts/${contact_id}/documents/${id}`);

export const getDocument = (contact_id) =>
  api.get(`/agents/contacts/${contact_id}/documents`);

export const contactProfileImage = ({ data, contact_id }) =>
  api.post(`/agents/contacts/${contact_id}/profile`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const updateContactAddress = (addresses, contact_id) =>
  api.post(`/agents/contacts/${contact_id}/property/address`, {
    addresses,
  });

export const updateContactInfo = (contact_id, data) =>
  api.put(`/agents/contacts/${contact_id}`, {
    ...data,
    first_name: data?.first_name,
    last_name: data?.last_name,
    phone: unmaskNumbers(data.phone),
    source_tag: data?.source_tag,
  });

export const getHomeOwnerRequests = (id) =>
  api.get(`/home-owner-requests`, {
    params: {
      contact_id: id,
      agent: "all",
    },
  });

export const updateAgreement = () => api.patch("/agents/tech-fee-agreement");
export const getPropertyDetails = (property_id) =>
  api.get(`agents/prospector/property/${property_id}`);

export const getOwnerInfo = (property_id) =>
  api.get(`agents/prospector/property/${property_id}/owner`);

export const addContactInfo = (property_id, data) =>
  api.post(`agents/prospector/property/${property_id}/reveal-owner-contact`, {
    city: data.city,
    state_code: data.state_code,
    street_line_1: data.street_line_1,
    postal_code: data.postal_code,
  });

export const gethistory = (property_id) =>
  api.get(`agents/prospector/property/${property_id}/history`);

export const getStatusData = (contact_id, property_id) =>
  api.get(`agents/contacts/${contact_id}/property-status/${property_id}`);

export const getProspectorProperties = (filters = {}, page, limit) => {
  let sort = null;
  const priceFilter = {
    mls_listing_price_min: filters?.min_price || null,
    mls_listing_price_max: filters?.max_price || null,
  };
  const filterParams = Object.assign(
    {},
    {
      size: limit,
      page: page,

      baths_min: filters?.min_bath || null,
      baths_max: filters?.max_bath || null,
      lot_size_min: filters?.min_lot_size || null,
      lot_size_max: filters?.max_lot_size || null,
      year_built_min: filters?.min_year_built || null,
      year_built_max: filters?.max_year_built || null,
      beds_min: filters?.min_bed || null,
      beds_max: filters?.max_bed || null,
      building_size_min: filters?.min_square_feet || null,
      building_size_max: filters?.max_square_feet || null,
      address: filters?.search || null,
      property_type: get(
        find(type_of_property, { label: filters?.property_types }),
        "value",
        null
      ),
      ...filters?.additionalFilter,
      polygon: filters?.search
        ? null
        : [
            {
              lat: filters?.lat_1 || null,
              lon: filters?.long_1 || null,
            },
            {
              lat: filters?.lat_2 || null,
              lon: filters?.long_2 || null,
            },
            {
              lat: filters?.lat_3 || null,
              lon: filters?.long_3 || null,
            },
            {
              lat: filters?.lat_4 || null,
              lon: filters?.long_4 || null,
            },
          ],
    }
  );

  // Check if sorting exists and add it to the filterParams
  if (
    filters?.order_by_field !== "created_at" &&
    filters?.order_by_field &&
    filters?.order_by
  ) {
    sort = {
      [filters.order_by_field]: filters.order_by,
    };
  }

  // Merge the lead types if present
  const leadTypes = merge({}, ...(filters?.lead_types || []));

  // Final API request call
  return api.post("agents/prospector/property", {
    filter: {
      ...filterParams,
      ...(sort && { sort }),
      ...leadTypes,
      ...priceFilter,
    },
  });
};

export const addESign = (offerID, url) =>
  api.post(`agents/esign/${offerID}`, {
    redirectUrl: url,
  });

export const inviteESign = (document_id, data, url) =>
  api.post(`agents/esign/invite`, {
    document_id,
    buyer_mail: data.buyer_mail,
    seller_mail: data.seller_mail,
    redirect_url: url,
  });

export const downloadESign = (document_id) =>
  api.post(`agents/esign/document/download`, {
    document_id,
  });

export const AddProperty = (data, contact_id) =>
  api.post("homehub/properties", { ...data, contact_id });
