import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

import { homeownerLoginSchema } from "../validation";
import { setToken } from "../../../shared/helpers/util";
import { homeownerLoginApi } from "../../../shared/api/homehub";
import FormError from "../../../shared/components/form-control/FormError";
import TogglePasswordVisibilityButton from "../../../shared/components/password-visibility/TogglePasswordVisibilityButton";

import FormLabel from "../../../shared/components/agent-form-control/FormLabel";
import FormInput from "../../../shared/components/agent-form-control/FormInput";
import FormButton from "../../../shared/components/agent-form-control/FormButton";
import ErrorAlertMessage from "../../../shared/components/server-error/ErrorAlertMessage";
import AppLabel from "../../../shared/components/white-label/AppLabel";

const Login = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    mutate: homeownerLoginMutate,
    isLoading: homeownerLoginIsLoading,
    error: homeownerLoginError,
    isError: homeownerLoginIsError,
  } = useMutation((contact) => homeownerLoginApi(contact), {
    onSuccess: (res) => {
      setToken("Token", res?.data?.token);

      navigate(
        !res?.data?.property_id
          ? `/properties`
          : `/property/${res?.data?.property_id}/details`
      );
    },
  });

  const { handleSubmit, handleChange, errors, values } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: homeownerLoginSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      homeownerLoginMutate(values);
    },
  });

  useEffect(() => {
    if (params.has("is_verified")) {
      toast("Your email verified successfully", { type: "success" });
    }
  }, [params]);

  return (
    <>
      <form
        className="p-14 maxLg:p-5 bg-white maxLg:max-w-[26.5625rem] w-full maxLg:rounded-lg"
        onSubmit={handleSubmit}
      >
        <div className="flex justify-center">
          <AppLabel
            classNameLogo={"!m-0 !mb-6 h-[6.25rem] md:w-[10.25rem]"}
            classNameLoader={"mb-[6.875rem] !justify-start"}
            classNameTextLogo={"mb-[5.625rem] !justify-start"}
          />
        </div>
        <h2 className="md:text-2xl text-xl text-metallicBlue font-bold block">
          Homeowner Login
        </h2>
        <p className="text-coolGrey mt-2 text-sm maxMd:text-xs">
          Log in to your Home Dashboard
        </p>
        <div className="mb-4 mt-6 maxLg:mt-3">
          <FormLabel
            title="Email"
            className="text-nickel font-medium mt-2 text-sm maxMd:text-xs"
          />
          <FormInput
            className={clsx(
              "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] mt-1 hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
              {
                "is-error": errors.email,
              }
            )}
            placeholder="Enter your email"
            type="text"
            name="email"
            onChange={handleChange}
            value={values.email}
          />
          <FormError error={errors.email} />
        </div>

        <div className="mb-4">
          <FormLabel
            title="Password"
            className={"text-nickel font-medium mt-2 text-sm maxMd:text-xs"}
          />
          <div className="relative mt-1">
            <FormInput
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="Enter password"
              className={clsx(
                "border hover:border-metallicBlue focus:border-metallicBlue rounded-lg w-full outline-0 p-2 h-[2.625rem] hover:shadow-agentsAuthInputs focus:shadow-agentsAuthInputs transition text-sm",
                {
                  "is-error": errors.password,
                }
              )}
              value={values.password}
              onChange={handleChange}
            />
            <TogglePasswordVisibilityButton
              isPasswordShown={passwordShown}
              togglePasswordVisibility={() => setPasswordShown(!passwordShown)}
            />
          </div>
          <FormError error={errors.password} />
        </div>


        <p
          onClick={() => navigate("/homehub/forgot-password")}
          className="text-sm my-4 maxLg:my-2 text-steelblue hover:text-[#394E77] font-medium cursor-pointer ml-auto text-right"
        >
          Forgot your password?
        </p>

        <FormButton
          type="submit"
          className="!bg-metallicBlue text-white w-full p-3 rounded-md mt-7 maxLg:mt-4 hover:!bg-[#4D6083] transition flex justify-center disabled:pointer-events-none disabled:opacity-[0.6] font-semibold items-center"
          title="Log in"
          loader={homeownerLoginIsLoading}
          disabled={homeownerLoginIsLoading}
        />

        <p
          onClick={() => navigate(`/homehub/signup`)}
          className="text-sm mt-5 maxLg:mt-3 text-[#6B7280] font-medium cursor-pointer text-center"
        >
          Don't have an account?{" "}
          <span className="text-steelblue hover:text-[#394E77]">Sign up</span>
        </p>
        {homeownerLoginIsError && (
          <ErrorAlertMessage className={"mt-6"} errors={homeownerLoginError} />
        )}
      </form>
    </>
  );
};

export default Login;
