import clsx from "clsx";

import React, { useCallback } from "react";

import { HO_PROPERTY_REQUEST_TYPE_CASH } from "../../../shared/helpers/constants";
import { numberWithCommas } from "../../../shared/helpers/util";

import style from "../../../assets/css/homeowner/home.module.css";

const CashOutEquity = ({
  setIsOpenPropertiesRequestModal,
  setRequestType,
  propertyData,
  homeownerRequestClick,
}) => {
  const onClickCashOutEquity = useCallback(() => {
    setIsOpenPropertiesRequestModal(true);
    setRequestType(HO_PROPERTY_REQUEST_TYPE_CASH);
    homeownerRequestClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={clsx(
        "p-6 flex flex-col justify-between pt-[1.188rem]",
        style.card
      )}
    >
      <div className="maxMd:pb-3">
        <h3 className={style.headingMd}>Cash out my equity</h3>
        <p className={clsx("my-2", style.description)}>
          You currenty have an estimated{" "}
          {propertyData?.last_estimated_equity > 0
            ? `${numberWithCommas(propertyData.last_estimated_equity)}`
            : "$0"}{" "}
          in home equity.
        </p>
        <p className={clsx("mt-3", style.description)}>
          Use cash from your home equity to make improvements, buy an investment
          property or to pay for any other necessary items.
        </p>
      </div>
      <div>
        <button
          className="dark-button_homehub w-full text-sm"
          onClick={onClickCashOutEquity}
        >
          Get cash from my equity
        </button>
      </div>
    </div>
  );
};

export default CashOutEquity;
